import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import swal from 'sweetalert';
import { v4 as uuidv4 } from 'uuid';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import http from "../../../apis/api";


function ConfirmationCode() {

    const history = useHistory();

    const [clientInput, setClient] = useState({
        code: '',
        email: '',
        token: '',
        error_list: [],
    });
    const [isLoading, setLoading] = useState(false);

    const handleInput = (e) => {
        e.persist();
        setClient({...clientInput, [e.target.name]: e.target.value })
    }

    const confirmationCode = (e) => {
        e.preventDefault();
        
        setLoading(true);
        const data = {
            code: clientInput.code,
            email: clientInput.email,
            token: uuidv4()
        }

        http.post(`/confirmation`, data).then(res => {
            if(res.status === 201)
            {
                setLoading(false);
                //swal("Success!",res.data.message,"success");

                history.push('/confirmation');
                setClient({
                    email: '',
                    code: '',
                    token: '',
                    error_list: []
                });
                
            }
            else if(res.data.status === 422)
            {
                setLoading(false);
                setClient({...clientInput, error_list: res.data.validate_err });
                console.log('Erreur ::: ' +res.data.validate_err);
                //swal("Erreur !",res.data.validate_err,"error");
            }else{
              //swal("Erreur inconnue !",res.data.validate_err,"error");
              console.log('Erreur inconnue ::: ' +res.data.validate_err);
            }
        });
    }


    return (
      <>

      <center>
        <div className="col-xl-4">
          <div className="event-d-sidebar">
            <div className="event-book-form">
              <div className="category-title">
                <i className="bi bi-bookmark-check" /> <h4>Confimation</h4>
              </div>
              <form id="event-book" onSubmit={confirmationCode} className="event-book">

                <div className="primary-input-group">
                  <input type="text" required  name="code" onChange={handleInput} value={clientInput.code} placeholder="Entrez le code de confirmation" />
                </div>

                <div className="submit-btn">
                  <button
                    disabled={isLoading}
                    type="submit"
                    className="primary-submit d-block w-100">
                    Confirmer
                  </button>
                </div>
              </form>
            </div>


          </div>
        </div>
        </center>
      </>
    );
  
}

export default ConfirmationCode;
