import React, { Component } from "react";
import Register from "./register";
import { Link } from "react-router-dom";

class Inscription extends Component {
  
    scrollTop() {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }

    render() {
      return (
        <>
        <div className="breadcrumb-area">
          <div className="container">
            <div className="row align-items-end">
              <div className="col-lg-12">
                <div className="breadcrumb-content">
                  <div className="page-outlined-text">
                    <h1>Inscrivez-vous</h1>
                  </div>
                  <h2 className="page-title">Inscription</h2>
                  <ul className="page-switcher">
                    <li>
                      <Link
                        onClick={this.scrollTop}
                        to={`${process.env.PUBLIC_URL}/`}>
                        Accueil <i className="bi bi-caret-right" />
                      </Link>
                    </li>
                    <li>Inscription</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
          <Register />
        </>
      );
    }
  }
  
  export default Inscription;