import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import swal from 'sweetalert';
import { v4 as uuidv4 } from 'uuid';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import http from "../../../apis/api";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

function Register() {

    const history = useHistory();

    const [clientInput, setClient] = useState({
        identite: '',
        email: '',
        adresse: '',
        datenaissance: '',
        lieunaissance: '',
        username: '',
        role: '',
        token: '',
        error_list: [],
    });
    const [isLoading, setLoading] = useState(false);

    const handleInput = (e) => {
        e.persist();
        setClient({...clientInput, [e.target.name]: e.target.value })
    }

    const saveClient = (e) => {
        e.preventDefault();
        
        setLoading(true);
        const data = {
            identite:clientInput.identite,
            email:clientInput.email,
            datenaissance:clientInput.datenaissance,
            lieunaissance:clientInput.lieunaissance,
            username:clientInput.username,
            role:clientInput.role,
            adresse: clientInput.adresse, 
            token: uuidv4()
        }

        http.post(`/client`, data).then(res => {
            if(res.status === 201)
            {
                setLoading(false);
                //swal("Success!",res.data.message,"success");
                setClient({
                    identite: '',
                    email: '',
                    adresse: '',
                    datenaissance: '',
                    lieunaissance: '',
                    username: '',
                    role: '',
                    token: '',
                    error_list: [],
                });
                history.push('/confirmation');
                
            }else{
              swal("Erreur inconnue !","Une erreur vient de se produire lors de votre inscription. Merci de réessayer plutard.","error");
              setLoading(false);
              setClient({...clientInput, error_list: res.data.validate_err });
              console.log('Erreur inconnue ::: ' +res.data.validate_err);
            }
        }).catch((error) => {
          swal("Erreur inconnue !","Une erreur incoonue vient de se produire lors de la soumision de votre demande. Merci de réessayer après !","error");
          setLoading(false);
          setClient({...clientInput, error_list: error });
      });
    }


    return (
      <>

      <center>
        <div className="col-xl-4">
          <div className="event-d-sidebar">
            <div className="event-book-form">
              <div className="category-title">
                <i className="bi bi-bookmark-check" /><h4>Inscription</h4>
              </div>
              <form id="event-book" onSubmit={saveClient} className="event-book">
                <div className="primary-input-group">
                  <input type="text" required name='identite' onChange={handleInput} value={clientInput.identite} placeholder="Votre Identite" />
                </div>
                <div className="primary-input-group">
                  <input type="email" required id="e-email" name="email" onChange={handleInput} value={clientInput.email} placeholder="Adresse e-mail" />
                </div>
                <div className="primary-input-group">
                  <input type="text" required  name="adresse" onChange={handleInput} value={clientInput.adresse} placeholder="Votre adresse physique" />
                </div>
                <div className="primary-input-group">
                  <input type="date" required id="date" name="datenaissance" onChange={handleInput} value={clientInput.datenaissance} placeholder="Date naissance" />
                </div>
                <div className="primary-input-group">
                  <input type="text" required id="lieu" name="lieunaissance" onChange={handleInput} value={clientInput.lieunaissance} placeholder="Lieu de naissance" />
                </div>
                <div className="primary-input-group">
                  <input type="text" required id="lname" name="username"  onChange={handleInput} value={clientInput.username} placeholder="Créer un nom d'utilisateur" />
                </div>
                <div className="primary-input-group">
                  <select defaultValue="" required onChange={handleInput} value={clientInput.role} name="role"  className="primary-select">
                    <option >Rôle ou Occupation</option>
                    <option value={"Directeur"} >Directeur</option>
                    <option value={"Directeur Adjoint"}>Directeur Adjoint</option>
                    <option value={"Docteur"} >Docteur</option>
                    <option value={"Expert"} >Expert</option>
                    <option value={"Ingénieur"} >Ingénieur</option>
                    <option value={"Autre"} >Autre</option>
                  </select>
                </div>
                <div className="submit-btn">
                  {  isLoading ? 
                    <center>
                      <Box sx={{ display: 'flex' }}>
                        <CircularProgress />
                      </Box>
                    </center> :  <button
                    disabled={isLoading}
                    type="submit"
                    className="primary-submit d-block w-100">
                    Créer un compte
                  </button>}

                </div>
              </form>
            </div>


          </div>
        </div>
        </center>
      </>
    );
  
}

export default Register;
