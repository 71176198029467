import React, { Component } from "react";
import HomeOneFooter from "../common/HomeOneFooter";
import HomeOneHeader from "../common/HomeOneHeader";
import HomePage from "../pages/home/HomePage";
import HomeTwoHeader from "../common/HomeTwoHeader";
import HomeTwoFooter from "../common/HomeTwoFooter";
import HomePageTwo from "../pages/HomeTwo/HomePageTwo";

class MainLayout extends Component {
  render() {
    return (
      <>
      {/* main layout  */}
        <HomeTwoHeader />
          <HomePageTwo />
        <HomeTwoFooter />
      </>
    );
  }
}

export default MainLayout;
