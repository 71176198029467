import React, { Component } from "react";
import { Link } from "react-router-dom";

import http from "../../../../apis/api";
class EventRightArea extends Component {


  handleSubmit(e){
    e.preventDefault();
    http
        .post('/client', {
            identite: this.state.identite,
            ref_user: this.state.ref_user,
            ref_rdv_setting: this.state.ref_rdv_setting,
            statut: this.state.statut,
            daterdv: this.state.daterdv,
            email_adress: this.state.email_adress,
            description: this.state.description,
            token: this.state.token
        })
        .then(response => {
            console.log('Retour ::: ', response);
      });
  } 

  scrollTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  render() {
    return (
      <>
        <div className="col-xl-4">
          <div className="event-d-sidebar">
            <div className="event-book-form">
              <div className="category-title">
                <i className="bi bi-bookmark-check" /> <h4>Prendre Rendez-vous</h4>
              </div>
              <form action="#" id="event-book" onSubmit={this.handleSubmit} className="event-book">
                <div className="primary-input-group">
                  <input type="text" name='identite' id="e-name" placeholder="Votre Identite" />
                </div>
                <div className="primary-input-group">
                  <input type="email" id="e-email" name="email" placeholder="Adresse e-mail" />
                </div>
                <div className="primary-input-group">
                  <input type="tel" id="e-tel" name="telephone" placeholder="Téléphone" />
                </div>
                <div className="primary-input-group">
                  <select defaultValue="" className="primary-select">
                    <option >Select quantity</option>
                    <option value={0} >Quantity 1</option>
                    <option value={1}>Quantity 2</option>
                    <option value={2} >Quantity 3</option>
                  </select>
                </div>
                <div className="primary-input-group">
                  <input type="text" id="lname" placeholder="Votre Adresse Physique" />
                </div>
                <div className="submit-btn">
                  <button
                    type="submit"
                    className="primary-submit d-block w-100">
                    Soumettre
                  </button>
                </div>
              </form>
            </div>


          </div>
        </div>
      </>
    );
  }
}

export default EventRightArea;
