import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import swal from 'sweetalert';
import { v4 as uuidv4 } from 'uuid';
import 'react-toastify/dist/ReactToastify.css';

//API SERVICE
import http from "../../../apis/api";

//TIME DATE
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticTimePicker } from '@mui/x-date-pickers/StaticTimePicker';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';


function RendezVous() {

    const history = useHistory();
    const {username} = useParams();
    const [value, setValue] = useState(new Date());
    const [dataclient, setDataClient] = useState([])

    const [clientInput, setClient] = useState({
        identite: '',
        email: '',
        adresse: '',
        daterdv: '',
        heurerdv: value,
        username: username,
        description: '',
        token: '',
        photo: '',
        error_list: [],
    });
    const [isLoading, setLoading] = useState(false);

    const handleInput = (e) => {
        e.persist();
        setClient({...clientInput, [e.target.name]: e.target.value })
    }

    const saveRendezvous = (e) => {
        e.preventDefault();
        
        setLoading(true);
        const data = {
            identite:clientInput.identite,
            email:clientInput.email,
            daterdv:clientInput.daterdv,
            heurerdv: value,
            username: username,
            description: clientInput.description,
            photo: clientInput.photo,
            adresse: clientInput.adresse, 
            token: uuidv4()
        }

        http.post(`/rendezvous`, data).then(res => {
            if(res.status === 201)
            {
                setLoading(false);
                swal("Merci "+data.identite+" !","Votre demande a été soumise avec succès. Vous allez recevoir un e-mail contenant plus de details sur votre demande de rendez-vous !","success");
                setClient({
                    identite: '',
                    email: '',
                    adresse: '',
                    datenaissance: '',
                    lieunaissance: '',
                    username: '',
                    role: '',
                    token: '',
                    error_list: [],
                });
                
            }else{
              swal("Erreur inconnue !","Une erreur vient de se produire lors de la soumision de votre demande. Merci de réessayer après !","error");
              setLoading(false);
              setClient({...clientInput, error_list: res.data.validate_err });
            }
        }).catch((error) => {
          swal("Erreur inconnue !","Une erreur incoonue vient de se produire lors de la soumision de votre demande. Merci de réessayer après !","error");
          setLoading(false);
          setClient({...clientInput, error_list: error });
      });
    }

    useEffect(() =>
    {
        http.get(`/client/search/${username}`)
        .then(res => {
            //swal("Erreur !","data ::: "+res.data.adresse,"error");
            setDataClient(res.data)
            console.log(res);
            console.log(res.data);
            if(res.status === 201){
                setDataClient(res.data)
            }else{
              history.push('/error');
            }
        })
        .catch(err =>{
            console.log(err)
        })
    }, [username]);

    return (
      <>

      <center>
        <div className="col-xl-6">
          <div className="event-d-sidebar">
            <div className="event-book-form">
              <div className="category-title">
                <i className="bi bi-bookmark-check" /><h4>Rendez-vous avec {username}</h4>
              </div>
              <form id="event-book" onSubmit={saveRendezvous} className="event-book">
                <div className="primary-input-group">
                  <input type="text" required name='identite' onChange={handleInput} value={clientInput.identite} placeholder="Votre Identite" />
                </div>
                <div className="primary-input-group">
                  <input type="email" required id="e-email" name="email" onChange={handleInput} value={clientInput.email} placeholder="Adresse e-mail" />
                </div>
                <div className="primary-input-group">
                  <input type="text" required  name="adresse" onChange={handleInput} value={clientInput.adresse} placeholder="Votre adresse physique" />
                </div>
                <div className="primary-input-group">
                  <input type="date" required id="date" name="daterdv" onChange={handleInput} value={clientInput.daterdv} placeholder="Date Rendez-vous" />
                </div>
              
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <StaticTimePicker
                        ampm
                        orientation="landscape"
                        openTo="minutes"
                        value={value}
                        onChange={(newValue) => {
                        setValue(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </LocalizationProvider>
                
                <div className="primary-input-group">
                    <label>Photo</label>
                    <input type="file" id="lname" name="photo"  onChange={handleInput} value={clientInput.photo}/>
                </div>

                <div className="primary-input-group">
                  <textarea  required id="ldesc" name="description"  onChange={handleInput} value={clientInput.description} placeholder="Ecrivez quelques choses" />
                </div>

                <div className="submit-btn">
                  {  isLoading ? 
                 <center>
                 <Box sx={{ display: 'flex' }}>
                   <CircularProgress />
                 </Box>
               </center> :  <button
                    disabled={isLoading}
                    type="submit"
                    className="primary-submit d-block w-100">
                    Soumettre
                  </button>}
                </div>
              </form>
            </div>


          </div>
        </div>
        </center>
      </>
    );
  
}

export default RendezVous;
